import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Colors } from '../../utils/ColorUtils';

interface ShrtFeatureProps {
  align: 'left' | 'right';
  image: string;
  titleDefaultLabel: string;
  titleId: string;
}

export function ShrtFeature({ align, children, image, titleDefaultLabel, titleId }: PropsWithChildren<ShrtFeatureProps>) {
  const imageComponent = (
    <div className="col-span-2">
      <div className={`text-center mb-4 text-xl font-semibold ${Colors.TEXT_INDIGO}`}>
        <FormattedMessage id={titleId} defaultMessage={titleDefaultLabel} />
      </div>
      <img className="rounded-xl shadow-xl" alt="alt" src={image} />
    </div>
  );
  const descriptionComponent = <div className="col-1 place-self-center">{children}</div>;
  const details = [imageComponent, descriptionComponent];

  return (
    <div className="flex flex-end mb-20">
      <div className="grid grid-cols-3 gap-8 px-8 justify-end">{align === 'left' ? details : details.reverse()}</div>
    </div>
  );
}
