import { Blockquote } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';

export default function ShrtAbout() {
  return (
    <div className="container mx-auto py-10 max-w-screen-md">
      <Blockquote>
        <FormattedMessage
          id="pages.about.block_quote"
          defaultMessage='\"I love to dig around company websites to get information about the technologies they use.\"'
        />
      </Blockquote>
      <figcaption className="mt-6 flex items-center justify-center space-x-3">
        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
          <cite className="pr-3 font-medium text-gray-900 dark:text-white">Antoine P.</cite>
          <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Software Engineer</cite>
        </div>
      </figcaption>
      <div className="pt-20 space-y-15">
        <p>
          <FormattedMessage
            id="pages.about.catch_phrase"
            defaultMessage="Like me, if you like to get information about the technologies used to build an app by looking up for the about page (or open positions) in the hope of getting insights about the development stack, you're at the right place."
          />
        </p>
        <p className="space-y-5">
          <FormattedMessage
            id="pages.about.stack"
            defaultMessage="Let's go into the details about <strong>shortenify</strong> app."
            values={{
              code: (chunks) => <code className="font-semibold">{chunks}</code>,
              p: (chunks) => <p>{chunks}</p>,
              strong: (chunks) => <span className="dark:text-white font-bold">{chunks}</span>,
            }}
          />
        </p>
      </div>
    </div>
  );
}
