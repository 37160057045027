import { FormattedMessage } from 'react-intl';
import graphiql from '../../assets/features/features-graphiql.png';
import links from '../../assets/features/features-link.png';
import map from '../../assets/features/features-statistics-map.png';
import { ShrtFeature } from '../../components/common/Feature';

export default function ShrtFeatures() {
  return (
    <div>
      <ShrtFeature align="left" titleDefaultLabel="Protect and share made easy" titleId="pages.features.titles.links" image={links}>
        <FormattedMessage
          id="pages.features.labels.links"
          defaultMessage="Easily share your link with a QR Code. Protect your link with a user/password authentication."
        />
      </ShrtFeature>
      <ShrtFeature align="right" titleDefaultLabel="Fully integrated Graphiql playground" titleId="pages.features.titles.graphiql" image={graphiql}>
        <FormattedMessage
          id="pages.features.labels.graphiql"
          defaultMessage="Pick-up one of your API key, and start playing with the Shortenify API. This Graphiql component is configured to interact with the Shortenify API exactly in the same way you will call it from your own app or API. Plus, you can directly subscribe to API key or link live updates thanks to the GraphQL subscriptions mechanism."
        />
      </ShrtFeature>
      <ShrtFeature align="left" titleDefaultLabel="World heatmap" titleId="pages.features.titles.statistics.map" image={map}>
        <FormattedMessage
          id="pages.features.labels.statistics.map"
          defaultMessage="View how many times and from which country your link has been opened since its creation. The more a link is opened, the more colorful the country is going to be."
        />
      </ShrtFeature>
    </div>
  );
}
